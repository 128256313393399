import { observeElement, getWidget } from '@uvocore-front/utils';

const siteJabberInit = () => {
  observeElement({
    element: '#reviewCarouselWidget',
    marginValue: 300,
    callback: () => {
      getWidget({
        widgetLink: '//static.sitejabber.com/js/widget.min.1644963448.js',
        loader: false,
        fn: () => {
          // eslint-disable-next-line
          new STJR.ReviewCarouselWidget({
            id: 'reviewCarouselWidget',
            url: 'cheapessaysonline.com',
          }).render();
        },
      });
    },
  });
};

siteJabberInit();
