/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function () {
  return this.each(function (_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({appearance: 'none', '::-ms-expand': 'none'});
    $icon.css({pointerEvents: 'none'});
  })
};

/**
 * Description: JS for cheapessaysonline.com
 * Version: 1.0.0
 * Last update: 2015/03/01
 * Author: UVOCorp
 *
 * Summary:
 *  1. Custom JS for cheapessaysonline.com
 *
 */

/* ==========================================================================
   1. Custom JS for cheapessaysonline.com
   ========================================================================== */
jQuery.cookie = function (name, value, options) {
  if (typeof value != 'undefined') {
    options = options || {};
    if (value === null) {
      value = '';
      options.expires = -1;
    }
    var expires = '';
    if (options.expires && (typeof options.expires == 'number' || options.expires
        .toUTCString)) {
      var date;
      if (typeof options.expires == 'number') {
        date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
      } else {
        date = options.expires;
      }
      expires = '; expires=' + date.toUTCString();
    }
    var path = options.path ? '; path=' + (options.path) : '';
    var domain = options.domain ? '; domain=' + (options.domain) : '';
    var secure = options.secure ? '; secure' : '';
    document.cookie = [name, '=', encodeURIComponent(value), expires, path,
      domain, secure
    ].join('');
  } else {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = jQuery.trim(cookies[i]);
        if (cookie.substring(0, name.length + 1) == (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
};

$(function ($) {
  $.getScript('/assets/js/calc.js', function() {
    if($('[data-calc-mini]').length > 0) {
      new Calc({
        ID: '41',
        format: 'json',
        options: {
          deafault: {
            pagesDefault: 1
          }
        }
      });
    }
  });

  $('[data-toggle="dropdown"]').on('click', function () {
    $('.btn-group').toggleClass('open');
  });

  $(document).mouseup(function (e) {
    var container = $(".dropdown-toggle");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $('.btn-group').removeClass('open');
    }
  });

  if ($.cookie('visited')) {
    $("#stepspic").addClass('short');
  } else {
    $.cookie('visited', 'true');
  }
  $("#stepspic").on('click', function () {
    $("#stepspic .rt-container").slideToggle(500, function () {
      $("#stepspic").toggleClass('short')
    })
  });
});

document.addEventListener('DOMContentLoaded', function () {

  if ($(window).innerWidth() < 979) {
    $('.sidebar_wrapper').toggleClass('compact');
    $('.compact_button').on('click', function () {
      $('.sidebar_wrapper').toggleClass('compact');
      $('html, body').toggleClass('no-scroll');
      $('.nav-overlay').fadeToggle();
      $('.nav-overlay').toggleClass('open');

      if ($('body').hasClass('no-scroll')) {
        $('.nav-overlay').css('height', window.innerHeight + 'px');

        $( window ).resize(function() {
          $('.nav-overlay').css('height', window.innerHeight + 'px');
        });

        var overlay = document.querySelector('.nav-overlay');
        var clientY = null;
        overlay.addEventListener('touchstart', function (event) {
          if (event.targetTouches.length === 1) {
          clientY = event.targetTouches[0].clientY;
          }
        }, false);
        overlay.addEventListener('touchmove', function (event) {
          if (event.targetTouches.length === 1) {
          disableRubberBand(event);
          }
        }, false);
        function disableRubberBand(event) {
          var _clientY = event.targetTouches[0].clientY - clientY;
          if (overlay.scrollTop === 0 && _clientY > 0) {
          event.preventDefault();
          }
          if (isOverlayTotallyScrolled() && _clientY < 0) {
          event.preventDefault();
          }
        }
        function isOverlayTotallyScrolled() {
          return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
        }


      }
    });
  }

});


(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '41',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();
